import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GlobalDataService} from '../../common/util/globaldata.service';
import {environment} from '../../../environments/environment';
import SiteDetailModel from '../../models/site-detail-model';
import SiteDetailRequestModel from '../../models/site-detail-request-model';
import OptInRequestModel from '../../models/optin-request-model';
import {UserOptinInfo} from '../../models/user-optin-info';
import {UtilityService} from '../../common/util/utils.service';
import {AwsWafWrapperService} from '../../services/util/aws-waf-wrapper.service';

@Injectable()
export class SiteManagerService {
  constructor(private http: HttpClient,
              private gd: GlobalDataService,
              private us: UtilityService,
              private awsWafWrapper: AwsWafWrapperService) {
  }

  private _siteDetailsApi = environment.SITE_DETAILS_API;
  private _eletterOrHotlistApi: string;
  private _calloutEventType: string;

  public getSiteDetails(): Observable<SiteDetailModel> {
    const siteRequest = this.mapSiteDetailRequest();
    if (siteRequest.cid && (siteRequest.eid || siteRequest.chainId)) {
      const _siteApiUrl = this._siteDetailsApi + this.httpBuildQuery(siteRequest);
      return this.http.get(_siteApiUrl)
        .pipe(
          map((site: SiteDetailModel) => {
            return this.extractSiteData(site);
          }),
          catchError((error: any) => {
            return this.handleErrorObservable(error, 'Site Details');
          }));
    }
  }

  getAsset(location: String) {
    return new Observable<string>(subscriber => {
      const url = this.us.getVersionedUrl(location);
      this.http.get(url, {responseType: 'text'}).subscribe((response) => {
        try {
          const parseResponse = JSON.parse(response);
          subscriber.next(parseResponse);
          subscriber.complete();
        } catch (error) {
          subscriber.next(response);
          subscriber.complete();
        }
      }, (error) => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  public singleOptIn(event: UserOptinInfo,
                     assetId: string): Observable<any> {
    const assetIdForOptinRequest = (assetId !== '' && assetId != null) ? assetId : this.gd.asset.sourceParentAssetId;
    this.getEventTypeAndOptinApi(this.gd.site.campaignType);
    const listSingleOptInRequest: Array<OptInRequestModel> = this.getOptinRequestBody(event, assetIdForOptinRequest,
      this.gd.asset.sourcePubCode, '', false, this._calloutEventType);
    return this.submitPostWithWaf(this._eletterOrHotlistApi, listSingleOptInRequest);
  }

  public doubleOptIn(
    event: UserOptinInfo,
    assetId: string,
    params: string,
    sendOnlyEmail: boolean
  ): Observable<any> {
    let doubleOptInUrl = params ? environment.CREATE_ELETTER_API : environment.DOUBLE_OPTIN_SEND_EMAIL_API;
    if (this.gd.queryParams.autotest) {
      doubleOptInUrl += '?autotest=' + this.gd.queryParams.autotest;
    }
    const doubleOptinEvent = params ? 'create_eletter' : 'double_optin_send_only_email';
    const listDoubleOptInRequest: Array<OptInRequestModel> = this.getOptinRequestBody(event, assetId,
      this.gd.asset.sourcePubCode, params, sendOnlyEmail, doubleOptinEvent);
    return this.submitPostWithWaf(doubleOptInUrl, listDoubleOptInRequest);
  }

  public coiOptIn(event: UserOptinInfo,
                  assetId: string,
                  sendOnlyEmail: boolean): Observable<any> {
    let coiOptInUrl = environment.CREATE_COI_API;
    if (this.gd.queryParams.autotest) {
      coiOptInUrl += '?autotest=' + this.gd.queryParams.autotest;
    }
    const assetIdForRequest = (assetId !== '' && assetId != null) ? assetId : this.gd.queryParams.assetId;
    const coiOptInRequest = this.mapOptInRequest(event,
      assetIdForRequest,
      this.gd.asset.sourcePubCode,
      '',
      sendOnlyEmail, 'set_coi_optin', true);
    const listCoiOptInRequest: OptInRequestModel[] = [coiOptInRequest];
    return this.submitPostWithWaf(coiOptInUrl, listCoiOptInRequest);
  }

  private mapOptInRequest(event: UserOptinInfo,
                          assetId: string,
                          pubCode: string,
                          params: string,
                          sendOnlyEmail: boolean,
                          eventType: string,
                          coi?: boolean) {
    const optInRequestModel = new OptInRequestModel();
    if (event && event.email) {
      optInRequestModel.email = event.email;
    }
    if (event && event.firstName) {
      optInRequestModel.firstName = event.firstName;
    }
    if (event && event.lastName) {
      optInRequestModel.lastName = event.lastName;
    }
    if (event && event.street) {
      optInRequestModel.address = event.street;
    }
    if (event && event.city) {
      optInRequestModel.city = event.city;
    }
    if (event && event.state) {
      optInRequestModel.state = event.state;
    }
    if (event && event.zipcode) {
      optInRequestModel.zipcode = event.zipcode;
    }
    if (event && event.country) {
      optInRequestModel.country = event.country;
    }
    if (event && event.phoneNumber) {
      optInRequestModel.phone = event.phoneNumber;
    }
    if (params) {
      optInRequestModel.doubleOptInParams = params;
    }
    const emailTemplateUrl = this.gd.asset.sourceEmailTemplateUrl;
    if (emailTemplateUrl) {
      optInRequestModel.emailTemplateUrl = decodeURIComponent(emailTemplateUrl);
    } else {
      optInRequestModel.emailTemplateUrl = '';
    }
    const emailSubject = this.gd.asset.sourceEmailSubject;
    if (emailSubject) {
      optInRequestModel.emailSubject = decodeURIComponent(emailSubject).replace(/\+/g, ' ');
    } else {
      optInRequestModel.emailSubject = 'Welcome to Eletter';
    }
    /* if (this.gd.siteDetail.freeEletterPubs) {
      optInRequestModel.createChildEletter = false;
    } */
    // ToDo have email subject in asset manager
    optInRequestModel.assetId = assetId;
    optInRequestModel.pubCode = pubCode;
    optInRequestModel.level = 'Regular';
    optInRequestModel.brandId = this.gd.site.brandId;
    optInRequestModel.isSendOnlyEmail = sendOnlyEmail;
    optInRequestModel.campaignId = this.gd.queryParams.campaignId;
    optInRequestModel.effortId = this.gd.queryParams.effortId;
    optInRequestModel.source = this.gd.queryParams.effortId;
    optInRequestModel.authOptIn = this.gd.asset.sourceIsAuthOptIn;
    optInRequestModel.campaignType = this.gd.site.campaignType;
    optInRequestModel.eventType = eventType;
    if (window.location.hostname === 'localhost') {
      optInRequestModel.baseUrl =
        window.location.protocol + '//' + window.location.hostname + ':4200';
    } else {
      optInRequestModel.baseUrl =
        window.location.protocol + '//' + window.location.hostname;
    }
    if (this.gd.queryParams.placementId) {
      optInRequestModel.placementId = this.gd.queryParams.placementId;
    }
    if (this.gd.queryParams.channel) {
      optInRequestModel.externalChannel = this.gd.queryParams.channel;
    }
    if (coi) {
      optInRequestModel.coiOptin = coi;
      optInRequestModel.eventTrigger = 'signup';
      optInRequestModel.baseUrl = window.location.href;
      this.http.get('https://jsonip.com').subscribe((data: any) => {
        const response = data.json();
        optInRequestModel.ipAddress = response.ip;
      });
      if (this.gd.queryParams.qryEmailJobId) {
        optInRequestModel.emailJobId = this.gd.queryParams.qryEmailJobId;
      }
      optInRequestModel.coiParams = this.gd.queryParams.coiParams;
    } else {
      if (this.gd.siteParams.selOfferChainId) {
        optInRequestModel.offerChainId = this.gd.siteParams.selOfferChainId;
      }
    }
    if (this.gd.queryParams.qryUtm_campaign) {
      optInRequestModel.utm_campaign = this.gd.queryParams.qryUtm_campaign;
    }
    if (this.gd.queryParams.qryUtm_content) {
      optInRequestModel.utm_content = this.gd.queryParams.qryUtm_content;
    }
    if (this.gd.queryParams.qryUtm_medium) {
      optInRequestModel.utm_medium = this.gd.queryParams.qryUtm_medium;
    }
    if (this.gd.queryParams.qryUtm_source) {
      optInRequestModel.utm_source = this.gd.queryParams.qryUtm_source;
    }
    if (this.gd.queryParams.qryUtm_term) {
      optInRequestModel.utm_term = this.gd.queryParams.qryUtm_term;
    }
    if (this.gd.site.freeEletterPubs) {
      optInRequestModel.childEletter = true;
    }
    return optInRequestModel;
  }

  private mapSiteDetailRequest() {
    const siteDetailRequest = new SiteDetailRequestModel();
    if (this.gd.queryParams.campaignId) {
      siteDetailRequest.cid = this.gd.queryParams.campaignId;
    } else {
      siteDetailRequest.cid = '';
    }
    if (this.gd.queryParams.effortId) {
      siteDetailRequest.eid = this.gd.queryParams.effortId;
    } else {
      siteDetailRequest.eid = '';
    }
    if (this.gd.queryParams.offerChainId) {
      siteDetailRequest.chainId = this.gd.queryParams.offerChainId;
    } else {
      siteDetailRequest.chainId = '';
    }
    if (this.gd.queryParams.placementId) {
      siteDetailRequest.plcid = this.gd.queryParams.placementId;
    } else {
      siteDetailRequest.plcid = '';
    }
    if (this.gd.queryParams.preview) {
      siteDetailRequest.preview = this.gd.queryParams.preview;
    } else {
      siteDetailRequest.preview = false;
    }
    if (this.gd.queryParams.autotest) {
      siteDetailRequest.autotest = this.gd.queryParams.autotest;
    }
    siteDetailRequest.baseUrl = window.location.host;
    return siteDetailRequest;
  }

  private httpBuildQuery(params) {
    if (typeof params === 'undefined' || typeof params !== 'object') {
      params = {};
      return params;
    }
    let query = '?', index = 0;
    for (const i of Object.keys(params)) {
      index++;
      const param = i, value = params[i];
      if (index === 1) {
        query += param + '=' + value;
      } else {
        query += '&' + param + '=' + value;
      }
    }
    return query;
  }

  private extractSiteData(site: SiteDetailModel): any {
    return site || {};
  }

  private submitPostWithWaf(url: string, body: any): Observable<any> {
    let headers = {
      'Content-Type': 'application/json'
    };
    return this.awsWafWrapper.post(url, JSON.stringify(body), headers);
  }

  private handleErrorObservable(error: Response | any, apiName: string) {
    if (error && error.status === 0) {
      return observableThrowError(() => new Error(`${apiName} - CORS Exception`));
    } else {
      return observableThrowError(() => new Error(`${apiName} - ${error.statusText}`));
    }
  }

  private getEventTypeAndOptinApi(cType: string) {
    if (cType && cType === 'E-Letter') {
      this._eletterOrHotlistApi = environment.CREATE_ELETTER_API;
      this._calloutEventType = 'create_eletter';
    } else if (cType && cType === 'Hot List') {
      this._eletterOrHotlistApi = environment.CREATE_HOTLIST_API;
      this._calloutEventType = 'create_hotlist';
    }
    if (this.gd.queryParams.autotest) {
      this._eletterOrHotlistApi += '?autotest=' + this.gd.queryParams.autotest;
    }
  }

  private getOptinRequestBody(event: UserOptinInfo,
                              assetIdForOptinRequest: string,
                              pubCode: string,
                              params: string,
                              sendOnlyEmail: boolean,
                              eventType: string) {
    const listOptInRequest: Array<OptInRequestModel> = [];
    if (this.gd.site.freeEletterPubs && (eventType === 'create_eletter' || eventType === 'create_hotlist')) {
      const freeEletterPubs: Array<string> = this.gd.site.freeEletterPubs.split(';');
      let reqPubcode = (this.gd.asset.sourcePubCode.trim() + ',');
      freeEletterPubs.forEach(freeEletterPub => {
        if (!this.us.contains(reqPubcode, freeEletterPub)) {
          reqPubcode += (freeEletterPub + ',');
        }
      });
      listOptInRequest.push(this.mapOptInRequest(event,
        assetIdForOptinRequest,
        reqPubcode,
        params,
        false, eventType));
    } else {
      listOptInRequest.push(this.mapOptInRequest(event,
        assetIdForOptinRequest,
        pubCode,
        params,
        sendOnlyEmail, eventType));
    }
    return listOptInRequest;
  }

}
