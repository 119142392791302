import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { GlobalDataService } from '../../common/util/globaldata.service';
import { UtilityService } from '../../common/util/utils.service';
import {UserOptinInfo} from '../../models/user-optin-info';

declare var $: any;
const SNA_SINGLE_OPTIN = '.sna-single-optin';
const SNA_DOUBLE_OPTIN = '.sna-double-optin';
const SNA_PAGE_FORM = '.sna-page-form';
@Component({
  moduleId: module.id,
  selector: 'asset-manager',
  templateUrl: './asset-manager.component.html',
  providers: []
})
export class AssetManagerComponent implements OnInit {
  @ViewChild('assetManagerElement') assetManagerElement: ElementRef;
  @Output('onNextPageClickEvent') onNextPageClickEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output('onSingleOptinClickEvent') onSingleOptinClickEvent: EventEmitter<UserOptinInfo> = new EventEmitter<UserOptinInfo>();
  @Output('onDoubleOptinClickEvent') onDoubleOptinClickEvent: EventEmitter<UserOptinInfo> = new EventEmitter<UserOptinInfo>();
  @Output('onInvalidUserInfo') onInvalidUserInfo: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('onCoiOptinClickEvent') onCoiOptinClickEvent: EventEmitter<UserOptinInfo> = new EventEmitter<UserOptinInfo>();

  constructor(private gd: GlobalDataService, private utilityService: UtilityService) {
  }

  private _htmlContent: string;

  get htmlContent(): string {
    return this._htmlContent;
  }

  @Input()
  set htmlContent(value: string) {
    if (value) {
      this._htmlContent = value;
      this.assetManagerElement.nativeElement.innerHTML = value;
      this.setupListeners();
      this.populateUserInfo();
    }
  }

  ngOnInit() {}

  private setupSubmitListener(element) {
    this.setupNextPageListener(element);
  }

  private setupListeners() {
    const element = this.assetManagerElement.nativeElement;
    this.setupOnBlurHideErrorsListener(element);
    this.setupSubmitListener(element);
  }

  private setupOnBlurHideErrorsListener(element) {
    const querySelectorList = [];
    Object.keys(this.gd.fieldSelectors).forEach((field: string) => {
      if(field === 'email' || field === 'zipcode' || field === 'phoneNumber')
        querySelectorList.push(`${this.gd.fieldSelectors[field].fieldClass}`);
      else
        querySelectorList.push(`${this.gd.fieldSelectors[field].fieldClass}:required`);
    });
    if (querySelectorList.length > 0) {
      const query = querySelectorList.join(', ');
      const requiredElements = element.querySelectorAll(query);
      this.bindOnBlurRemoveError(element, requiredElements);
    }
  }

  private setupNextPageListener(element) {
    this.disableOptin();
    this.hideErrors();
    const nextPageLinkElements = element.querySelectorAll('.sna-next-page');
    this.bindOnNextPageClickEvent(element, nextPageLinkElements);
    const singleOptinElements = element.querySelectorAll(SNA_SINGLE_OPTIN);
    this.bindOnSingleOptinClickEvent(element, singleOptinElements);
    const doubleOptinElements = element.querySelectorAll(SNA_DOUBLE_OPTIN);
    this.bindOnDoubleOptinClickEvent(element, doubleOptinElements);
  }

  private bindOnNextPageClickEvent(element, nextPageLinkElements) {
    if (nextPageLinkElements.length) {
      for (const nextPageLinkElement of nextPageLinkElements) {
        nextPageLinkElement.addEventListener('click', event => {
          this.onNextPageClickEvent.emit('Next');
        });
      }
    }
  }

  private bindOnBlurRemoveError(element, requiredElements) {
    if (requiredElements && requiredElements.length) {
      for (const requiredElement of requiredElements) {
        requiredElement.addEventListener('blur', event => {
          const target = event.target.closest(SNA_PAGE_FORM) || element;
          const field = this.getFieldFromElement(requiredElement);
          if (field) {
            this.hideError(field);
            const invalidField = this.invalidFields(target).find(f => f.field === field);
            if (invalidField) {
              this.showErrors([invalidField]);
            }
          }
        });
      }
    }
  }

  private getFieldFromElement(element): string {
    let result = null;
    if (element && element.className) {
      element.className.split(' ').forEach((fieldClass: string) => {
        Object.keys(this.gd.fieldSelectors).forEach(field => {
          if (this.gd.fieldSelectors[field].fieldClass === `.${fieldClass}`) {
            result = field;
            return;
          }
        });
        if (result) {
          return;
        }
      });
    }
    return result;
  }

  private getRequiredFields(): string[] {
    const querySelectorList = [];
    const requiredFields = [] as string[];
    Object.keys(this.gd.fieldSelectors).forEach((field: string) => {
        querySelectorList.push(`${this.gd.fieldSelectors[field].fieldClass}:required`);
    });
    if (querySelectorList.length > 0) {
      const query = querySelectorList.join(', ');
      const requiredElements = this.assetManagerElement.nativeElement.querySelectorAll(query);

      if (requiredElements && requiredElements.length) {
        for (const requiredElement of requiredElements) {
          requiredFields.push(this.getFieldFromElement(requiredElement));
        }
      }
    }
    return requiredFields;
  }

  private bindOnSingleOptinClickEvent(element, singleOptinElements) {
    if (singleOptinElements.length) {
      this.hideErrors();
      for (const singleOptinElement of singleOptinElements) {
        singleOptinElement.addEventListener('click', event => {
          $(SNA_SINGLE_OPTIN).attr('disabled', 'disabled');
          const queryElements = {};
          const target = event.target.closest(SNA_PAGE_FORM) || element;
          Object.keys(this.gd.fieldSelectors).forEach(field => {
            queryElements[field] = target.querySelectorAll(this.gd.fieldSelectors[field].fieldClass);
          });

          const invalidFields = this.invalidFields(target);

          if (!invalidFields.length) {
            this.onSingleOptinClickEvent.emit(this.getUserOptinInfo(queryElements));
          } else {
            this.enableSubmitButton(SNA_SINGLE_OPTIN);
            this.onInvalidUserInfo.emit(invalidFields);
            this.showErrors(invalidFields);
          }
        });
      }
    }
  }

  private enableSubmitButton(element) {
    $(element).removeAttr('disabled');
    $(element).removeClass('disabled');
    $(element).attr('disabled', false);
  }

  private bindOnDoubleOptinClickEvent(element, doubleOptinElements) {
    if (doubleOptinElements.length) {
      this.hideErrors();
      for (const doubleOptinElement of doubleOptinElements) {
        doubleOptinElement.addEventListener('click', event => {
          $(SNA_DOUBLE_OPTIN).attr('disabled', 'disabled');
          const queryElements = {};
          const target = event.target.closest(SNA_PAGE_FORM) || element;
          Object.keys(this.gd.fieldSelectors).forEach(field => {
            queryElements[field] = target.querySelectorAll(this.gd.fieldSelectors[field].fieldClass);
          });

          const invalidFields = this.invalidFields(target);

          if (!invalidFields.length) {
            this.onDoubleOptinClickEvent.emit(this.getUserOptinInfo(queryElements));
          } else {
          this.enableSubmitButton(SNA_DOUBLE_OPTIN);
            this.onInvalidUserInfo.emit(invalidFields);
            this.showErrors(invalidFields);
          }
        });
      }
    }
  }

  private getUserOptinInfo(queryElements): UserOptinInfo {
    const event = new UserOptinInfo();
    Object.keys(this.gd.fieldSelectors).forEach(field => {
      event[field] = this.getSelectorObjValue(queryElements[field]);
    });
    event.requiredFields = this.getRequiredFields();

    return event
  }

  private disableOptin() {
    const element = this.assetManagerElement.nativeElement;
    if (element) {
      const snaFormElement = element.querySelector(SNA_PAGE_FORM);
      if (snaFormElement) {
        snaFormElement['onsubmit'] = function () {
          return false;
        };
      }
    }
  }

  private hideErrors() {
    Object.keys(this.gd.fieldSelectors).forEach(field => {
      this.hideError(field);
    });
  }

  hideError(field: string) {
    const element = this.assetManagerElement.nativeElement;
    const errorDisplay = element.querySelectorAll(this.gd.fieldSelectors[field].errorClass);
    if (errorDisplay.length) {
      for (const error of errorDisplay) {
        error.style.display = 'none';
      }
    }
  }

  private showErrors(invalidFields: any[]) {
    const element = this.assetManagerElement.nativeElement;
    invalidFields.forEach(invalidField => {
      const errorDisplay = element.querySelectorAll(this.gd.fieldSelectors[invalidField.field].errorClass);
      if (errorDisplay.length) {
        for (const error of errorDisplay) {
          error.style.display = 'block';
        }
      }
    });
  }

  private populateUserInfo() {
    if (this.gd.user) {
      Object.keys(this.gd.fieldSelectors).forEach(field => {
        this.setUserInfo(field, this.gd.fieldSelectors[field].fieldClass);
      });
    }
  }

  private setUserInfo(field: string, querySelector: string) {
    if (this.gd.user[field] && this.gd.user[field].trim()) {
      const element = this.assetManagerElement.nativeElement;
      if (element.querySelector(querySelector)) {
        (<HTMLInputElement>element.querySelector(querySelector)).value = this.gd.user[field];
        element.querySelector(querySelector).disabled = true;
      }
    }
  }

  private invalidFields(target): any[] {
    const result = [] as any[];

    Object.keys(this.gd.fieldSelectors).forEach(field => {
      const selector = target.querySelectorAll(this.gd.fieldSelectors[field].fieldClass);
      if (!this.isFieldValid(selector, field)) {
        result.push({selector: selector, field: field});
      }
    });
    return result;
  }

  private isFieldValid(selector: any[], field: string): boolean {
    const value = this.getSelectorObjValue(selector);
    if (this.selectorObjIsRequired(selector)) {
      if (!value) {
        return false;
      }
    }
    if ((value && value.trim()) || field === 'email') {
      if (field === 'email') {
        return this.utilityService.isValidEmail(value);
      } else if (field === 'zipcode') {
        return this.utilityService.isValidZipcode(value);
      } else if (field === 'phoneNumber') {
        return this.utilityService.isValidPhoneNumber(value);
      }
    }
    return true;
  }

  private getSelectorObjValue(selector: any[]): string {
    if (!selector) {
      return null;
    } else {
      if (selector && selector.length > 0) {
        const obj = selector[0];
        if (obj.value && obj.value.trim()) {
          return obj.value.trim();
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  private selectorObjIsRequired(selector: any[]): boolean {
    if (!selector) {
      return false;
    } else {
      if (selector && selector.length > 0) {
        const obj = selector[0];
        return obj.required;
      } else {
        return false;
      }
    }
  }
}
